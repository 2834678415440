<template>
  <div>
    <base-toolbar>
      <template v-slot:icon>mdi-domain</template>
      <template v-slot:filters>
        <v-col cols="7" sm="3" md="2" lg="2" class="pl-0 pl-sm-3">
          <v-text-field
            hide-details
            label="Search"
            placeholder="Search"
            :value="filterKeyword"
            solo
            flat
            background-color="grey"
            dense
            single-line
            append-icon="mdi-magnify"
            class="shrink mx-2"
            @input="$emit('update:filter-keyword', $event)"
          />
        </v-col>

        <v-btn
          v-if="showClearFilterButton"
          color="grey"
          class="mx-2"
          fab
          small
          :elevation="0"
          @click="clearFilter"
        >
          <v-icon color="error">mdi-close-circle-outline</v-icon>
        </v-btn>
        <v-spacer />

        <div class="ml-auto">
          <v-btn
            color="primary"
            class="mr-0 d-none d-md-inline"
            depressed
            @click="createItem"
          >
            <v-icon class="mr-1">mdi-domain-plus</v-icon>
            <span class="hideTitleBtnP">New Client</span>
          </v-btn>
          <v-menu
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-flex d-md-none"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                @click="createItem"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-domain-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text text--lighten-2">New Client</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </base-toolbar>
    <create-client
      :show-create-dialog.sync="showCreateDialog"
      v-on:reload-items="$emit('reload-items')"
    />
  </div>
</template>

<script>
import BaseToolbar from "@/components/toolbar/BaseToolbar"
import { clientCountry } from "@/data/common"
import CreateClient from "@/components/dialog/clients/CreateClientDialog.vue"

export default {
  components: {
    CreateClient,
    BaseToolbar
  },
  props: {
    filterKeyword: {
      type: String,
      default: null
    },
    filterCountry: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      clientCountry,
      showCreateDialog: false
    }
  },
  computed: {
    showClearFilterButton() {
      return !!(this.filterKeyword || this.filterCountry)
    }
  },
  methods: {
    createItem() {
      this.showCreateDialog = true
    },
    clearFilter() {
      this.$emit("update:filter-keyword", "")
      this.$emit("update:filter-country", null)
    }
  }
}
</script>
