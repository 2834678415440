<template>
  <div>
    <v-card flat>
      <edit-client-dialog
        :edited-item="editedItem"
        :show-edit-dialog.sync="showEditDialog"
        v-on:reload-items="fetchClients"
      />
      <delete-client-dialog
        :edited-item="editedItem"
        :show-delete-dialog.sync="showDeleteDialog"
        v-on:reload-items="fetchClients"
      />
      <v-data-table
        :headers="headers"
        :items="clients"
        :server-items-length="clientCount"
        :loading="isLoading"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions: pageSizeOptions}"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-domain
            </v-icon>
          </v-avatar>
        </template>
        <template v-slot:item.name="{ item }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold pointer primary--text text--lighten-2" @click="fullViewItem(item)">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>

        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>{{ new Date (item.createdAt).toLocaleDateString('en-CA') }}</div>
        </template>

        <!--<template v-slot:item.isActive="{ item }">
          <v-avatar v-if="item.isActive" color="success lighten-3" size="24">
            <v-icon dark> mdi-check </v-icon>
          </v-avatar>
        </template>-->

        <template v-slot:item.subscriptionSource="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.subscriptionSource === subscriptionSources.internal"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">
                  mdi-alpha-v-box-outline
                </v-icon>
              </v-avatar>
            </template>
            <span>Internal</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.subscriptionSource === subscriptionSources.azure"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">
                  mdi-microsoft-azure
                </v-icon>
              </v-avatar>
            </template>
            <span>Azure</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.subscriptionSource === subscriptionSources.aws"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="warning">
                  mdi-aws
                </v-icon>
              </v-avatar>
            </template>
            <span>AWS</span>
          </v-tooltip>
        </template>

        <template v-slot:item.isLocked="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="!item.isLocked"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">
                  mdi-lock-open-variant
                </v-icon>
              </v-avatar>
            </template>
            <span>Unlock</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.isLocked"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">
                  mdi-lock
                </v-icon>
              </v-avatar>
            </template>
            <span>Lock</span>
          </v-tooltip>
        </template>

        <template v-slot:item.action="{ item }">
          <span class="d-none d-lg-flex">
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  class="mx-0"
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Client</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="danger"
                  class="mx-0"
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete Client</span>
            </v-tooltip>
            <v-spacer />
          </span>
          <v-menu
            bottom
            left
            class="d-md-flex d-lg-none"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-md-flex d-lg-none ml-7"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item
                link
                class="primary--text text--lighten-2"
                @click="editItem(item)"
              >
                <v-icon class="mr-2" color="primary lighten-2">mdi-pencil</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                :class="item.isDeleted ? 'gray--text' : 'error--text'"
                :disabled="item.isDeleted"
                @click="deleteItem(item)"
              >
                <v-icon class="mr-2" :class="item.isDeleted ? 'gray--text' : 'error--text'">mdi-delete-outline</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DeleteClientDialog from '@/components/dialog/clients/DeleteClientDialog'
import EditClientDialog from '@/components/dialog/clients/EditClientDialog'
import { subscriptionSources } from '@/data/common'
import _ from 'lodash'
import ClientService from '@/services/client.service'

export default {
  components: {
    DeleteClientDialog,
    EditClientDialog
  },
  props: {
    filterSearch: {
      type: String || undefined,
      default: ""
    }
  },
  data: function() {
    return {
      subscriptionSources,
      editedItem: {},
      showDeleteDialog: false,
      showEditDialog: false,
      search: this.$route.query.search || '',
      clients: [],
      clientCount: 0,
      pageSizeOptions: [5, 10, 25],
      isLoading: false,
      debounceSearch: null,
      options: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || ''
      },
      previousCall: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || ''
      },
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'icon',
          width: '20px',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: 'Source',
          value: 'subscriptionSource',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1',
          width: '120px'
        },
        {
          text: 'Status',
          value: 'isLocked',
          align: 'center',
          width: '120px',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Created on',
          value: 'createdAt',
          align: 'left',
          width: '160px',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
          align: 'center',
          width: '120px',
          class: 'text-center font-weight-medium subtitle-1'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchClientsHandler()
      },
      deep: true
    }
  },
  mounted() {
    this.fetchClients()
  },
  methods: {
    searchAfterWait() {
      this.search = this.filterSearch
      if (!this.debounceSearch) {
        this.debounceSearch = _.debounce(this.fetchClientsHandler, 700)
      }
      this.debounceSearch()
    },
    fetchClientsHandler() {
      const newCall = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.search
      }
      if (
        newCall.page === this.previousCall.page
        && newCall.itemsPerPage === this.previousCall.itemsPerPage
        && newCall.search === this.previousCall.search
      ) {
        return
      }
      if (
        (
          newCall.itemsPerPage !== this.previousCall.itemsPerPage
          || newCall.search !== this.previousCall.search
        ) && this.options.page !== 1
      ) {
        this.options.page = 1 // reset to page 1 will retrigger the call
        return
      }
      this.previousCall = newCall
      this.fetchClients()
    },
    fullViewItem(item) {
      this.editedItem = Object.assign({}, item)
      this.$router.push(`/pentests/?clientId=${item.id}`)
    },
    fetchClients() {
      this.isLoading = true
      const { page, itemsPerPage } = this.options
      ClientService.getClients(
        page, itemsPerPage, this.search, {}
      ).then((clients) => {
        this.clients = clients.results
        this.clientCount = clients.total
      }).catch(() => {})
      this.isLoading = false
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showEditDialog = true
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showDeleteDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
table.v-table tbody td,
table.v-table tbody th {
  min-height: 50px !important
}
</style>
