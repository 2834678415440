<template>
  <div>
    <base-dialog
      :show="showEditDialog"
      icon="mdi-pencil"
      form-title="Edit Client"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              NAME <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="editedItem.name"
              :rules="[v => !!v || 'Name is required', v => (v && v.length >= 2) || 'Name must be at least 2 characters']"
              placeholder="Enter client name"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              SHORT NAME <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="editedItem.shortName"
              :rules="[v => !!v || 'Name is required', v => (v && v.length >= 2) || 'Name must be at least 2 characters']"
              placeholder="Enter short client name"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              LANGUAGE<span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-select
              v-model="editedItem.language"
              placeholder="Enter industry"
              :items="languages"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              ADDRESS <small class="font-italic font-weight-thin">(Optional)</small>
            </div>
            <v-text-field
              v-model="editedItem.address"
              placeholder="Enter address"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              CITY <small class="font-italic font-weight-thin">(Optional)</small>
            </div>
            <v-text-field
              v-model="editedItem.city"
              placeholder="Enter city"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              COUNTRY <small class="font-italic font-weight-thin">(Optional)</small>
            </div>
            <v-select
              v-model="editedItem.country"
              placeholder="Enter country"
              :items="clientCountry"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              ZIP CODE <small class="font-italic font-weight-thin">(Optional)</small>
            </div>
            <v-text-field
              v-model="editedItem.postalCode"
              placeholder="Enter zip code"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              PHONE <small class="font-italic font-weight-thin">(Optional)</small>
            </div>
            <v-text-field
              v-model="editedItem.phone"
              placeholder="Enter phone number"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              INDUSTRY <small class="font-italic font-weight-thin">(Optional)</small>
            </div>
            <v-select
              v-model="editedItem.industry"
              placeholder="Enter industry"
              :items="clientIndustry"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">PENDING</div>
            <v-switch
              v-model="editedItem.isLocked"
              class="mt-0 ml-1"
              :label="editedItem.isLocked ? 'Lock' : 'Unlock'"
              inset
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">STATUS</div>
            <v-switch
              v-model="editedItem.isActive"
              class="mt-0 ml-1"
              :label="editedItem.isActive ? 'Active' : 'Inactive'"
              inset
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import ClientService from '@/services/client.service'
import snackbarPlugin from '@/plugins/snackbar'
import { clientIndustry, clientCountry, languages } from '@/data/common'

export default {
  components: {
    BaseDialog
  },
  props: {
    showEditDialog: {
      type: Boolean,
      required: true
    },
    editedItem: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      clientIndustry,
      clientCountry,
      languages,
      valid: true
    }
  },
  methods: {
    close() {
      this.$emit('update:show-edit-dialog', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Updating ...')
        ClientService.putClient(this.editedItem.id, this.editedItem)
          .then((res) => {
            snackbarPlugin.showSuccess(`Client ${this.editedItem.name} updated!`)
            this.$emit('reload-items')
            this.close()
          }).catch((error) => {
            // console.log(error)
          })
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
