<template>
  <div class="px-1">
    <client-toolbar
      :filter-keyword.sync="filterSearch"
      :filter-country.sync="filterCountry"
      v-on:reload-items="refreshClients"
    />

    <client-table
      ref="clientTable"
      :filter-search.sync="filterSearchUpdate"
    />
  </div>
</template>

<script>
import ClientToolbar from '../components/toolbar/ClientToolbar.vue'
import ClientTable from '@/components/table/ClientTable.vue'

export default {
  name: 'Clients',
  metaInfo: {
    title: 'Clients'
  },
  components: {
    ClientTable,
    ClientToolbar
  },
  data: () => ({
    filterSearch: null,
    filterCountry: null
  }),
  computed: {
    filterSearchUpdate() {
      if (this.filterSearch !== null) {
        this.$refs.clientTable.searchAfterWait()
        return this.filterSearch
      }
    }
  },
  mounted() {
    this.$store.dispatch('changeThemeLoadingState', false)
  },
  methods: {
    refreshClients() {
      this.$refs.clientTable.fetchClients()
    }
  }
}
</script>
