<template>
  <div>
    <base-dialog
      :show="showCreateDialog"
      form-title="New Client"
      icon="mdi-domain-plus"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="px-6"
        >
          <v-row dense>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                NAME <span class="h6 primary--text text--lighten-2">*</span>
              </div>
              <v-text-field
                v-model="name"
                :rules="[v => !!v || 'Name is required', v => (v && v.length >= 2) || 'Name must be at least 2 characters']"
                placeholder="Enter client name"
                clearable
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                SHORT NAME <span class="h6 primary--text text--lighten-2">*</span>
              </div>
              <v-text-field
                v-model="shortName"
                :rules="[v => !!v || 'Name is required', v => (v && v.length >= 2) || 'Name must be at least 2 characters']"
                placeholder="Enter short client name"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                LANGUAGE <span class="h6 primary--text text--lighten-2">*</span>
              </div>
              <v-select
                v-model="language"
                :items="languages"
                placeholder="Select language"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row v-if="canChooseTeam" dense>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                TEAM <span class="h6 primary--text text--lighten-2">*</span>
              </div>
              <v-select
                v-model="team"
                :items="teams"
                placeholder="Select team"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                ADDRESS <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-text-field
                v-model="address"
                placeholder="Enter address"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                CITY <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-text-field
                v-model="city"
                placeholder="Enter city"
                clearable
                outlined
                dense
              />
            </v-col>

            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                COUNTRY <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-select
                v-model="country"
                :items="countries"
                placeholder="Selecte country"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                ZIP CODE <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-text-field
                v-model="postalCode"
                clearable
                placeholder="Enter zip code"
                outlined
                dense
              />
            </v-col>

            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                PHONE <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-text-field
                v-model="phone"
                clearable
                placeholder="Enter phone number"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                INDUSTRY <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-select
                v-model="industry"
                :items="clientIndustry"
                placeholder="Select industry"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row class="section2NC">
            <v-col cols="12">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                STATUS
              </div>
              <v-switch
                v-model="status"
                class="mt-0"
                inset
                outlined
                :label="`${status ? 'Active' : 'Inactive'}  `"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import ClientService from '@/services/client.service'
import snackbarPlugin from '@/plugins/snackbar'
import { clientIndustry, countries, languages, teams } from '@/data/common.js'
import UserService from '@/services/user.service'

export default {
  components: {
    BaseDialog
  },
  props: {
    showCreateDialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    name: null,
    shortName: null,
    language: null,
    team: null,
    address: null,
    city: null,
    country: null,
    postalCode: null,
    phone: null,
    industry: null,
    status: true,
    valid: true,
    canChooseTeam: false,
    clientIndustry,
    countries,
    languages,
    teams
  }),
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.canChooseTeam = profile.team === null
    }
  },
  methods: {
    resetForm() {
      this.name = null
      this.shortName = null
      this.language = null
      this.team = null
      this.address = null
      this.city = null
      this.country = null
      this.postalCode = null
      this.phone = null
      this.industry = null
      this.status = false
    },
    close() {
      this.$emit('update:show-create-dialog', false)
      this.resetForm()
    },
    save() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Creating ...')
        ClientService.postClient({
          name: this.name,
          shortName: this.shortName,
          language: this.language,
          team: this.team,
          address: this.address,
          city: this.city,
          country: this.country,
          postalCode: this.postalCode,
          phone: this.phone,
          industry: this.industry,
          status: this.status
        }).then(() => {
          this.$emit('reload-items')
          snackbarPlugin.showSuccess(`Client ${this.name} created!`)
          this.close()
        })
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>

<style lang="scss">
.section1NC{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section2NC{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.v-stepper__header {
  box-shadow: none;
}
.v-stepper {
  box-shadow: none;
}
</style>
